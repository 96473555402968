import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import { HTMLContent } from '../components/Content';

export const Place = ({ image, title, description }) => (
  <div className="row place">
    <div className="col-md-4 order-sm-2 order-md-1">
      <Img
        fixed={image.childImageSharp.fixed}
        alt={title}
        className="img-thumbnail"
      />
    </div>

    <div className="col-md-8 order-sm-1 order-md-2">
      <h3 className="mt-3">{title}</h3>

      <HTMLContent content={description} />
    </div>
  </div>
);

Place.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export const TrebonPageTemplate = ({ content, places }) => (
  <article className="trebon">
    <section className="row trebon__content">
      <div className="col">
        <HTMLContent content={content} />
      </div>
    </section>

    <section className="trebon__places">
      {places.map((place, i) => {
        return <Place {...place} key={i} />;
      })}
    </section>
  </article>
);

export default ({ data, location }) => {
  const { markdownRemark: page, site } = data;

  return (
    <Layout location={location}>
      <Helmet
        title={`${site.siteMetadata.title} | ${page.frontmatter.title}`}
      />

      <TrebonPageTemplate
        content={page.html}
        places={get(data.allMarkdownRemark, 'edges', []).map(i => ({
          ...i.node.frontmatter,
          description: i.node.html,
        }))}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query TrebonPageQuery($path: String!) {
    markdownRemark(frontmatter: { urlPath: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }

    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { type: { eq: "places" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            image {
              childImageSharp {
                fixed(width: 255) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            title
          }
        }
      }
    }
  }
`;
